/* 飞天云动-解决方案-职业培训 h5端*/
<template>
  <v-app>
    <v-banner single-line class="banner h5-carousel-new">
      <v-img
        src="https://h5.ophyer.cn/official_website/banner/LavOccupationH5.png"
        height="100%"
        :transition="false"
        class="d-flex align-center"
        
      >
        <template v-slot:placeholder>
          <v-row class="fill-height ma-0" align="center" justify="center">
            <v-progress-circular
              indeterminate
              size="80"
              color="red lighten-5"
            ></v-progress-circular>
          </v-row>
        </template>
      </v-img>
      <div class="h5-banner-info" style="margin-top: -10px;">
        <div class="width-per-100">
          <div class="font-size-h5banner-title ml-13">职业培训</div>
        </div>
      </div>
    </v-banner>
    <v-card color="#2181EA" flat class="py-6 position-r mt-5">
      <v-card-title class="justify-center font-size-20 color-FFF pa-0 line-height-28">{{data.module.title}}</v-card-title>
      <v-tabs v-model="tab" background-color="transparent" color="white" dark
              class="xs-tab">
        <v-tab v-for="(v, k) in data.module.itemList" :key="k" class="pa-0"
               :class="[k === 0 ? 'ml-5':'ml-6',k === data.module.itemList.length - 1 ? 'mr-5':'']"
               style="min-width: unset">
          <b class="color-FFF font-size-16 letter-spacing" style="font-weight: 400">{{ v.title }}</b>
        </v-tab>
      </v-tabs>
      <v-tabs-items class="mt-3 mx-5" v-model="tab" style="border-radius: 10px;">
        <v-tab-item v-for="(v, k) in data.module.itemList" :key="k" :transition="false">
          <v-card flat color="white" class="pa-5">
            <v-img :src="v.src" width="100%" min-height="200" contain>
              <template v-slot:placeholder><img-placeholder></img-placeholder></template>
            </v-img>
            <v-card-title class="pa-0 mt-4 font-size-16 line-height-25">{{v.title}}</v-card-title>
              <v-card-text class="pa-0 my-1 line-height-26"  v-text="v.content" />
          </v-card>
        </v-tab-item>
      </v-tabs-items>
      <div class="tab-btn-prev" @click.stop="handleTabBtn('prev')"></div>
      <div class="tab-btn-next" @click.stop="handleTabBtn('next')"></div>
    </v-card>
    <v-card flat class="pb-6 h5model_list1">
      <v-card-title class="justify-center font-size-20 color-333 pa-0 mt-6 line-height-28">{{data.module1.title}}</v-card-title>
      <div class="mt-4 px-3">
        <v-row v-for="(v,k) in data.module1.itemList" :key="k" class="justify-center mt-5">
          <div class="model_item">
            <img class="item_img" :src='v.src' />
            <div class="item_info">
              <v-card-title class="justify-center reset-title font-size-16">{{ v.title }}</v-card-title>
              <v-card-text class="reset-text font-size-14">{{ v.content }}</v-card-text>
            </div>
          </div>
        </v-row>
      </div>
    </v-card>
    <v-card class="px-4 py-6" flat>
      <v-card-title class="justify-center  font-size-20 color-#333333 pa-0 line-height-28 mt-6 mb-5">{{data.module2.title}}</v-card-title>
      <div class="box">
        <div class="right">
          <v-img :src="data.module2.src" contain />
        </div>
        <div class="left">
          <v-card-title class="font-size-16  py-0 px-0 mt-3">{{data.module2.imgTitle}}</v-card-title>
          <v-card-text class="font-size-14 mt-3 pa-0"  style="color:#666" v-text="data.module2.imgText"></v-card-text>
        </div>
      </div>
    </v-card>
    <v-card class="justify-center wap4" :flat="true" style="margin-bottom: 20px;">
      <v-card-title class="justify-center  font-size-20 color-#333333 pa-0 line-height-28">{{data.module3.title}}</v-card-title>
      <v-container class="max-width-1200 mt-6">
        <v-row justify="center" >  
          <v-col cols="12" v-for="(n,ind) in data.module3.itemList" :key="ind" class="d-flex justify-center"
                 style="padding: 10px 20px;">
            <v-card flat class="advantage_card">
              <div class="img_title rounded-xl" justify="center" :class="ind%2==0?'marginLeft':'marginRight'">
                <v-img height="210" :src="n.src" class="align-center rounded-xl" gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)">
                  <div class="white--text font-size-16 pl-6 pr-6  " style="text-align:center;">{{n.title}}</div>
                  <div class="white--text font-size-12 pl-6 pr-6 pb-5 mt-4" style="text-align:center;line-height: 20px;height:55px;color:#666">{{n.content}}</div>
                </v-img>
              </div>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </v-app>
</template>

<script>
import lavData from '@/static/lavData.js'
export default {
  name: "lavOccupation",
  data: () => ({
    data:lavData.lavOccupation,
    tab: null,
    imgLoad:false,
    bannerLoad: false,
  }),
  mounted(){
    this.bannerLoad = true;
  },
  methods: {
    handleLoad (){
      this.imgLoad = true;
    },
      handleTabBtn: function (type){
        const maxTab = this.data.module.itemList.length - 1;
        if(type === "prev"){
          if(this.tab === 0){
            this.tab = maxTab;
          }else {
            this.tab--;
          }
        }else if(type === "next"){
          if(this.tab === maxTab){
            this.tab = 0;
          }else {
            this.tab++;
          }
        }
      }
  }
};
</script>

<style lang="scss" scoped>
.banner{
  position: relative;
  ::v-deep .v-banner__wrapper,::v-deep .v-banner__content,::v-deep .v-banner__text{
    padding: 0 !important;
    height: 100%;
  }
  .banner-box {
    margin-top: -100px;
    .banner-text {
      p:nth-child(1) {
        font-size: 82px;
        //font-family: SourceHanSansCN-Regular;
        font-weight: 400;
        color: #fff;
        line-height: 88px;
      }
      p:nth-child(2) {
        margin-top: 25px;
        font-size: 24px;
        font-weight: 100 !important;
        color: #fff;
      }
      .banner-btn {
        margin-top: 90px;
        width: 200px;
        height: 60px;
        background: #0568FD;
        border-radius: 35px;
        font-size: 20px !important;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 60px;
        text-align: center;
        cursor: pointer;
      }
    }
  }
  @media (min-width: 960px) {
    .container {
      max-width: 1200px !important;
    }
  }
  
}
::v-deep .v-card__text{
  line-height: 20px!important;
}
.h5model_list1 {
  width: 100%;
  position: relative;
  .model_item{
    width: 300px;
    height: 210px;
    background: #FFFFFF;
    border-radius: 16px;
    padding: 15px 12px;
    text-align: center;
    // border: 1px solid red;
    .item_img{
      width: 100px;
      height: 100px;
      object-fit: contain;
    }
    .item_info{
      margin-top: 10px;
    }
  }
}
.ulStyle{
  li{
    list-style:disc;
    line-height:26px;
    color: #666;
  }
}
</style>